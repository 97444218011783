
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { formatDateTime } from "@/core/helpers/date";
import Pagination from "@/components/Pagination.vue";
import ViewEventUsersModal from "@/views/admin/ViewEventUsersModal.vue";

export default defineComponent({
  name: "AdminEvents",
  components: { Pagination, ViewEventUsersModal },
  setup() {
    const store = useStore();
    const route = useRoute();

    const userID = ref(route.params.userID);

    let search = ref("");
    let currentPageURL = ref("");
    let visibility = ref<null | any>(null);
    let online = ref<boolean>(false);

    visibility.value = route.query?.visibility;
    online.value = route.query?.online == "true" ? true : false;

    let isLoading = computed(() => {
      return store.getters[Modules.EVENT + "getIsLoading"];
    });
    let events = computed(() => {
      return store.getters[Modules.EVENT + "getEvents"];
    });
    let eventsMeta = computed(() => {
      return store.getters[Modules.EVENT + "getMeta"];
    });
    let eventUsers = computed(() => {
      return store.getters[Modules.EVENT + "getEventUsers"];
    });

    // let filteredEvents = computed(() =>
    //   events.value.length > 0
    //     ? events.value.filter(
    //         (item) =>
    //           item.type.toUpperCase().includes(search.value.toUpperCase()) ||
    //           item.visibility
    //             .toUpperCase()
    //             .includes(search.value.toUpperCase()) ||
    //           item.name.toUpperCase().includes(search.value.toUpperCase()) ||
    //           item.event_uid
    //             .toUpperCase()
    //             .includes(search.value.toUpperCase()) ||
    //           !search.value
    //       )
    //     : events.value
    // );

    onMounted(() => {
      setCurrentPageTitle("Events");
    });

    fetchRecords();

    function refresh() {
      fetchRecords();
    }

    function searchNow() {
      currentPageURL.value = "";
      fetchRecords();
    }

    function gotoPage(url) {
      currentPageURL.value = url;
      fetchRecords();
    }

    function fetchRecords() {
      store.dispatch(Modules.EVENT + Actions.FETCH_EVENTS, {
        search: search.value,
        userID: userID.value,
        visibility: visibility.value,
        online: online.value,
        url: currentPageURL.value,
      });
    }

    function fetchEventUsers(id) {
      store.dispatch(Modules.EVENT + Actions.FETCH_EVENT_USERS, id);
    }

    watch(visibility, (value) => {
      currentPageURL.value = "";
      fetchRecords();
    });

    watch(online, (value) => {
      currentPageURL.value = "";
      fetchRecords();
    });

    return {
      formatDateTime,
      search,
      isLoading,
      events,
      eventsMeta,
      visibility,
      online,
      // filteredEvents,
      eventUsers,
      refresh,
      searchNow,
      gotoPage,
      fetchEventUsers,
    };
  },
});
